<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Consultas de la categoría&nbsp;<strong>{{categoria?categoria.nombre:'...'}}</strong></v-card-title>
      <!-- <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        >+ Nuevo</CButton
      > -->

      <v-card-text>
        <filters
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="items"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
          <template #activo="data">
            <td>
              <CBadge :color="data.item.activo?'success':'secondary'">
                {{ data.item.activo==1?'Habilitado':'Inhabilitado' }}
              </CBadge>
            </td>
          </template>
          <template #acciones="data">
            <td>
              <CLink
                href="#"
                @click="verConsulta(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Abrir formulario de consulta'"
              >
                <CIcon name="cil-magnifying-glass" />
              </CLink>
            </td>
          </template>
        </CDataTable>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="goBack">Volver</CButton>&nbsp;
      </v-card-actions>
    </v-card>
    <br><br>
  </div>
</template>

<script>
import Filters from "@/components/filters";
import definicionConsultaService from "@/services/definicionConsulta.service";
import ListaService from "@/services/lista.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Layout",
  data() {
    return {
      rolesStr: [],
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "nombre", label: "Nombre", _classes: "font-weight-bold" },
        { key: "acciones", label: "Acciones" },
      ],
      filterFields: [
        { value: "id", label: "Código" },
        { value: "nombre", label: "Nombre" },
      ],
      categoria: undefined,
      activePage: 1,
      loading: false,
      message: "",
    };
  },
  components: {
    Filters,
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions", "roles"]),
  },
  mounted() {
    this.rolesStr = this.roles.map(item => item.name)
    if (!this.permissions.includes("Autogestor - Consulta"))
      this.$router.push("/dashboard");
    if (localStorage.getItem("datos_autogestor_categoria")) {
      this.categoria = JSON.parse(
        localStorage.getItem("datos_autogestor_categoria")
      );
    }  
    this.fetchItems("?activo=1&categoria_id="+this.categoria.id);
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda+"&activo=1&categoria_id="+this.categoria.id);
    });
    // this.fetchCategoriasConsultas('')
  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      definicionConsultaService.getItems(cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          
          this.items = this.items.filter( this.checkRoles )

          console.log(this.items);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    checkRoles(item) {
      let rolesPermitidos = JSON.parse(item.roles_acceso)
      let acceso = false
      for(let i = 0; i <rolesPermitidos.length; i++) {
        if (this.rolesStr.includes(rolesPermitidos[i]))
          acceso = true
      }
      return acceso
    },
    fetchCategoriasConsultas(cadenaBusqueda) {
      this.loadingCategorias = true;
      ListaService.getCategoriasConsultas().then(
        (response) => {
          this.filterFields[2].options = response.data.data;
          this.filterFields[2].options = this.filterFields[2].options.map(
            function f(x) {
              return { ...x, name: x.valor_texto };
            }
          );
          this.loadingCategorias = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCategorias = false;
        }
      );
    },
    verConsulta(item) {
      localStorage.setItem("datos_autogestor_preconsulta", JSON.stringify(item));
      this.$router.push({ path: `/autogestor/categorias/consultas/consulta/` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    goBack() {
      this.$router.push({ path: "/autogestor/categorias" });
    },
  },
};
</script>
